<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        color="msaBlue"
        class="white--text"
        @click="exportData()"
        v-on="on"
      >
        <v-icon> mdi-download </v-icon>
      </v-btn>
    </template>
    <span>Export</span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'ExportCompanyGroups',
  props: {
    search: {
      type: String,
    },
    options: {
      type: Object,
    },
  },
  methods: {
    exportData() {
      let params = {
        search: this.search ?? '',
        sortDesc:
          this.options.sortDesc[0] != undefined
            ? this.options.sortDesc[0]
            : false,
        sortBy:
          this.options.sortBy[0] != undefined ? this.options.sortBy[0] : 'name',
        loaderText: 'loading...',
      };

      let url = 'export-company-groups?format=json';

      this.$axios
        .post(url, params)
        .then(r => {
          const url =
            process.env.VUE_APP_BASE_URL +
            'download-export-company-groups?jwtToken=' +
            this.$store.getters.jwtToken +
            '&guid=' +
            r.data;
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display: none';
          a.target = '_blank';
          a.href = url;
          a.download = 'download';
          a.click();
          document.body.removeChild(a);
        })
        .catch(error => error);
    },
  },
};
</script>
