<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="6">
        <SearchBar
          @search="
            search = $event;
            options.page = 1;
          "
          searchLabel="Search by group name..."
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="shrink">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="msaBlue"
              class="white--text"
              @click="goToDetail()"
              v-on="on"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Group</span>
        </v-tooltip>
      </v-col>
      <v-col class="shrink">
        <ExportCompanyGroups :search="search" :options="options" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="msaBlue white--text"> Groups </v-card-title>
          <v-data-table
            :headers="headers"
            :items="groups"
            :server-items-length="groupsCount"
            :footer-props="{
              'items-per-page-options': [20, 30, 50],
            }"
            :options.sync="options"
            :items-per-page.sync="perPage"
            :page.sync="page"
            v-if="!isMobile"
          >
            <template v-slot:item="{ item }">
              <tr>
                <td class="msaBlue--text">
                  <span @click="goToDetail(item)" style="cursor: pointer">
                    {{ item.name }}
                  </span>
                </td>
                <td class="text-center">
                  {{ item.companiesCount }}
                </td>
                <td align="center">{{ item.licenses }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-blur
                        text
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="goToDetail(item)"
                      >
                        <v-icon color="msaBlue"> mdi-pencil </v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-data-table
      :items="groups"
      hide-default-header
      :server-items-length="groupsCount"
      :footer-props="{
        'items-per-page-options': [20, 30, 50],
      }"
      :options.sync="options"
      :items-per-page.sync="perPage"
      :page.sync="page"
      v-if="isMobile"
    >
      <template v-slot:item="{ item }">
        <v-card class="my-3" color="msaBlue">
          <v-card-title class="pb-5">
            <v-row>
              <v-col class="white--text pb-0 grow font-weight-medium">
                <span @click="goToDetail(item)" style="cursor: pointer">
                  {{ item.name }}
                </span>
              </v-col>
              <v-col class="shrink">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-blur
                      text
                      small
                      v-bind="attrs"
                      v-on="on"
                      @click="goToDetail(item)"
                    >
                      <v-icon color="white"> mdi-pencil </v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text style="background-color: #ebf1fc" class="msaBlue--text">
            <v-row>
              <v-col> Companies Count: </v-col>
              <v-col>
                {{ item.companiesCount }}
              </v-col>
            </v-row>
            <v-row>
              <v-col> Licenses: </v-col>
              <v-col>
                {{ item.licenses }}
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import ExportCompanyGroups from '@/components/CompanyGroups/ExportCompanyGroups.vue';
import SearchBar from '@/components/SearchBar.vue';
export default {
  name: 'CompanyGroups',
  components: {
    SearchBar,
    ExportCompanyGroups,
  },
  watch: {
    options: {
      handler() {
        this.getGroupsCount();
        this.getGroups();
      },
    },
    search: function () {
      this.getGroupsCount();
      this.getGroups();
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      reload: false,
      search: '',
      groups: [],
      groupsCount: 0,
      page: this.$constants.PAGINATION.DEFAULT_PAGE,
      perPage: this.$constants.PAGINATION.DEFAULT_PERPAGE,
      options: {},
      headers: [
        {
          text: 'Name',
          value: 'name',
          align: 'start',
          class: 'lightGrey',
        },
        {
          text: 'Companies Count',
          value: 'companiesCount',
          align: 'center',
          class: 'lightGrey',
          width: '150px',
        },
        {
          text: 'Licenses',
          value: 'licenses',
          align: 'center',
          class: 'lightGrey',
          width: '100px',
          sortable: false,
        },
        {
          text: 'Actions',
          align: 'center',
          class: 'lightGrey',
          width: '80px',
          sortable: false,
        },
      ],
    };
  },
  methods: {
    getGroups() {
      if (this.search == null) {
        this.search = '';
      }

      let params = {
        page: this.options.page,
        perPage: this.options.itemsPerPage,
        search: this.search,
        sortDesc:
          this.options.sortDesc[0] != undefined
            ? this.options.sortDesc[0]
            : false,
        sortBy:
          this.options.sortBy[0] != undefined ? this.options.sortBy[0] : 'name',
        loaderText: 'loading...',
      };
      this.employees = [];

      let url = 'get-company-groups?format=json';

      this.$axios
        .post(url, params)
        .then((response) => {
          this.groups = response.data;
        })
        .catch((error) => {
          return error;
        });
    },
    getGroupsCount() {
      if (this.search == null) {
        this.search = '';
      }

      let params = {
        search: this.search,
      };

      let url = 'get-company-groups-count?format=json';

      this.$axios
        .post(url, params)
        .then((response) => (this.groupsCount = response.data))
        .catch((error) => error);
    },
    goToDetail(group) {
      if (group == undefined) {
        this.$store.commit('updateSelectedGroup', {
          name: '',
          id: -1,
        });
      } else {
        this.$store.commit('updateSelectedGroup', {
          name: group.name,
          id: group.id,
        });
      }

      this.$router.push({
        name: 'IAGroupDetail',
        params: { id: this.$store.getters.selectedGroup.id },
      });
    },
  },
  mounted() {},
};
</script>
<style></style>
